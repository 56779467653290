import React from "react"
import { graphql } from "gatsby"
import LayoutHighlight from "../components/LayoutHighlight"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "../styles/highlight.css"
import BackgroundImage from "gatsby-background-image"
import moment from "moment"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <LayoutHighlight>
      <SEO title={frontmatter.tmdb[0].title} />
      <div className="highlight-post-container">
        <div className="highlight-post">
          <BackgroundImage
            fluid={frontmatter.tmdb[0].backdrop_path.localFile.childImageSharp.fluid}
          >
            <div
              style={{
                minHeight: 100,
                color: `white`,
                background: `rgba(88, 0, 0, 0.6)`,
              }}
            >
              <div
                style={{
                  margin: `0 auto`,
                  maxWidth: 960,
                  padding: `1.3rem 1.0875rem 0.5rem`,
                }}
              >
                <h3
                  style={{
                    color: `white`,
                    float: `left`,
                  }}
                >
                  {frontmatter.tmdb[0].title}
                </h3>
                <p
                  style={{
                    fontStyle: "italic",
                    fontSize: "0.9em",
                    float: `right`,
                  }}
                >
                  {frontmatter.tmdb[0].release_date},{` `}
                  {frontmatter.tmdb[0].runtime} min.,
                  {` `}
                  {frontmatter.tmdb[0].spoken_languages.map((item, index) => (
                    <span key={index}>{(index ? ", " : "") + item.name}</span>
                  ))}
                </p>
                <div style={{ clear: `both` }} />
                <div
                  dangerouslySetInnerHTML={{
                    __html: frontmatter.tmdb[0].overview,
                  }}
                />
              </div>
            </div>
          </BackgroundImage>
          <div
            className="highlight-main"
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              paddingTop: 5,
            }}
          >
            <Img
              className="poster-image"
              fixed={frontmatter.tmdb[0].poster_path.localFile.childImageSharp.fixed}
              style={{
                width: 230,
                height: 345,
              }}
            />
            <div
              style={{
                padding: 10,
              }}
              className="highlight-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            {frontmatter.cal != null && frontmatter.cal.length !== 0 && (
              <>
                <h4>Showtimes</h4>
                <ul>
                  {frontmatter.cal.map((item, index) => (
                    <li key={index} style={{ listStyle: "none", margin: 0 }}>
                      <strong>{item.location}:</strong>
                      {` `}
                      {moment(item.start)
                        .local()
                        .format(`dddd, MMMM D, YYYY - h:mma`)}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
      <div style={{ clear: "both" }} />
    </LayoutHighlight>
  )
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { 
      path: { 
        eq: $path 
      }
    }) {
      html
      frontmatter {
        path
        title
        cal {
          start
          location
        }
        tmdb {
          title
          overview
          tagline
          runtime
          release_date(formatString: "YYYY")
          spoken_languages {
            name
          }
          backdrop_path {
            localFile {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          }
          poster_path {
            localFile {
            childImageSharp {
              fixed(width: 230, height: 345) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          }
        }
      }
    }
  }
`
